export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    $('.owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      items: 3,
      responsiveClass: true,
      dots: false,
      autoplay: false,
      responsive: {
        0:{
          items:2,
          nav:true,
          loop:true,
        },
        1120:{
          items:3,
        },
      },
    });
  },
};
